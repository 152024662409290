import { DEALS_COUNTRIES } from '../config';
import { BC_ONLY_DEAL_COUNTRIES } from '../config/dealsCountries';

export const checkUserCountry = (userCountry, userRegion) => {
    if (!userCountry) return false;

    if (userRegion && userCountry === 'US') {
        return userCountry === 'US' && userRegion === 'NY';
    }

    return DEALS_COUNTRIES.includes(userCountry);
};

export const checkUserCountryForBCOnly = (userCountry) => {
    if (!userCountry) return false;
    return BC_ONLY_DEAL_COUNTRIES.includes(userCountry);
}